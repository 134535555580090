import { GroupBase } from 'react-select'
import { Message } from 'react-hook-form'
import React, { forwardRef } from 'react'

import AsyncSelect, { AsyncProps } from 'react-select/async'
import DropdownIndicator from '@common/components/form/select/components/dropdown-indicator'
import { ISelectOption } from '../multi-select'
import { SelectWrapper } from '@common/components/form/select/styles'
import { classNames } from '@common/utils/helpers'
import { css } from '@emotion/react'
import customOption from '@common/components/form/select/components/option-custom'
import toolTipSingleValue from '@common/components/form/select/components/single-value-tooltip'
import { useCombinedRefs } from '@common/utils/use-hooks'
import { useIntl } from '@palqee/intl'
import { white } from '@common/styles/constants'

export interface PqAsyncSelectProps
  extends Partial<
    AsyncProps<ISelectOption, boolean, GroupBase<ISelectOption>>
  > {
  borderRadius?: string
  inputMarginLeft?: number
  name?: string
  className?: string
  disabled?: boolean
  caption?: string
  placeholder?: React.ReactNode
  errorMessage?: Message
  errorBoard?: boolean
  readOnly?: boolean
  value?: { value: string; label: string } | { value: string; label: string }[]
  isDropSelect?: boolean
  refRegister?: any
  autoComplete?: 'on' | 'off'
  shouldUsePortal?: boolean
  buttonComponent?: React.ReactNode
}

export const PqAsyncSelect = forwardRef<HTMLDivElement, PqAsyncSelectProps>(
  (props, forwardedRef) => {
    const intl = useIntl()
    const {
      borderRadius,
      inputMarginLeft,
      id,
      name,
      className,
      disabled,
      caption,
      placeholder = intl.formatMessage({
        id: 'SELECT',
        defaultMessage: 'Select',
      }),
      errorMessage,
      readOnly,
      value,
      errorBoard,
      isSearchable = false,
      refRegister,
      buttonComponent,
      noOptionsMessage = () => (
        <span
          css={css`
            font-size: 11px;
          `}
        >
          {intl.formatMessage({
            id: 'NO_OPTIONS',
            defaultMessage: 'No options available',
          })}
        </span>
      ),
      isMulti = false,
      components,
      shouldUsePortal = false,
      ...rest
    } = props

    const classes = classNames('pq-select', className || '')

    const boardClasses = classNames(
      'pq-select-input-board',
      (disabled && '-disabled') || '',
      (errorMessage && '-error') || '',
      (buttonComponent && 'pq-is-relative') || '',
    )
    const titleClasses = classNames(
      'pq-select-input-title',
      (disabled && '-disabled') || '',
    )

    const combinedRefs = useCombinedRefs(refRegister, forwardedRef)

    const handleValueSet = () => {
      if (Array.isArray(value) || value?.value) {
        return value
      }
      return null
    }

    return (
      <SelectWrapper style={{ borderRadius }} inputMarginLeft={inputMarginLeft}>
        <div data-cy="form-async-select" className={boardClasses}>
          {caption && <span className={titleClasses}> {`${caption}`}</span>}
          <AsyncSelect
            menuPlacement="auto"
            id={id}
            className={classes}
            classNamePrefix="pq-select"
            placeholder={placeholder}
            noOptionsMessage={noOptionsMessage}
            name={name}
            menuPortalTarget={
              shouldUsePortal && typeof window !== 'undefined'
                ? document.body
                : undefined
            }
            components={{
              DropdownIndicator,
              SingleValue: (singleValueProps) => {
                return toolTipSingleValue(singleValueProps)
              },
              Option: customOption,
              ...components,
            }}
            instanceId={name}
            menuIsOpen={readOnly ? false : undefined}
            isDisabled={disabled}
            isSearchable={isSearchable}
            isMulti={isMulti}
            ref={combinedRefs}
            cacheOptions
            blurInputOnSelect
            defaultOptions
            styles={{
              control: (styles) => ({
                ...styles,
                height: 'auto',
                borderRadius: '8px',
              }),
              menu: (styles) => ({
                ...styles,
                top: '90%',
                margin: '0',
              }),
              menuList: (styles) => ({
                ...styles,
                width: '100%',
                paddingTop: '0',
                paddingBottom: '0',
                backgroundColor: white,
                opacity: 1,
              }),
              option: (styles) => ({
                ...styles,
              }),
            }}
            value={handleValueSet()}
            {...rest}
          />
          {buttonComponent && buttonComponent}
        </div>
        {errorBoard && (
          <div
            data-cy="form-async-select-error-msg"
            className="pq-error-message"
          >
            {errorMessage}
          </div>
        )}
      </SelectWrapper>
    )
  },
)
