import * as yup from 'yup'

/* eslint-disable import/namespace */
import { ApolloError, makeVar } from '@apollo/client'

import { GraphQLError } from 'graphql'
import { LOGIN } from '@common/components/consts'
import { captureException } from '@sentry/nextjs'
import { defineMessages } from '@features/core/react-intl/define-messages'
import isEmpty from 'lodash/isEmpty'
import { isObject } from '@common/utils/helpers'
import { onError } from '@apollo/client/link/error'
import { signOut } from 'next-auth/react'
import { signOutRequest } from '../next-ssr/lib'
import { GraphQLErrors } from '@apollo/client/errors'

type GraphQLErrorsValidations = {
  type: string
  params: [string, string, (value: any) => boolean]
}

export type GraphQLErrorsVar = {
  field?: string
  name?: string
  code?: string
  message?: string
  validations?: GraphQLErrorsValidations[]
}

type GraphQLErrorProps<G> = G extends ApolloError ? G : readonly GraphQLError[]

interface HandleGraphQLErrorProps {
  error: GraphQLErrorProps<ApolloError> | string
  handlers?: {
    [key: string]: (props: any) => void | JSX.Element | Element
  }
}

export enum GraphQLExceptionCode {
  AuthUserAlreadyActivated = 'AuthUserAlreadyActivated',
  DocumentFileNameAlreadyExists = 'DocumentFileNameAlreadyExists',
  AuthSignUpPhoneNumberAlreadyUsed = 'AuthSignUpPhoneNumberAlreadyUsed',
  AuthSignUpEmailAlreadyUsed = 'AuthSignUpEmailAlreadyUsed',
  AuthOneTimeTokenAlreadyUsed = 'AuthOneTimeTokenAlreadyUsed',
  AuthOneTimeTokenExpired = 'AuthOneTimeTokenExpired',
  AuthLoginWrongCredentials = 'AuthLoginWrongCredentials',
  CompanyNameAlreadyUsed = 'CompanyNameAlreadyUsed',
  NotAuthorizedException = 'NotAuthorizedException',
  InvalidParameterException = 'InvalidParameterException',
  AuthNotAuthenticated = 'AuthNotAuthenticated',
  ApiInputValidationFailed = 'ApiInputValidationFailed',
  AuthLoginMfaCodeExpired = 'AuthLoginMfaCodeExpired',
  AuthLoginMfaCodeInvalid = 'AuthLoginMfaCodeInvalid',
  AuthUserNotActivated = 'AuthUserNotActivated',
  AuthUserNotPending = 'AuthUserNotPending',
  AuthForbiddenTenant = 'AuthForbiddenTenant',
  JwtTokenExpired = 'JwtTokenExpired',
  RefreshAccessTokenError = 'RefreshAccessTokenError',
  JwtTokenNotFromPalqee = 'JwtTokenNotFromPalqee',
  SurveyResponseDuplicate = 'SurveyResponseDuplicate',

  // this may not be enough
  ApiInputInvalid = 'ApiInputInvalid',

  GenericError = 'GenericError',
}

export const graphQLErrorsMakeVar = makeVar<GraphQLErrorsVar[]>([])

export const unsetGraphQLError = ({
  field = '',
  code,
}: { [key in string]?: string }) => {
  const graphqlErrorsVar: GraphQLErrorsVar[] = graphQLErrorsMakeVar()
  const foundErrorIndex = graphqlErrorsVar.findIndex(
    (error) => error?.name === field || error?.code === code,
  )
  graphqlErrorsVar.splice(foundErrorIndex, 1)
}

export const createYupSchema = ({ fields, graphQLErrors, intl }) => {
  const yupSchema = fields?.reduce((schema, field) => {
    const {
      name,
      validationType,
      validationTypeError,
      validations = [],
    } = field

    if (graphQLErrors.length > 0) {
      const foundError = graphQLErrors.find((error) => error?.name === name)

      if (foundError) {
        // replace codes with translated messages
        const newValidation = foundError?.validations.map((v) => {
          const code = v?.params[1]

          return {
            ...v,
            params: v?.params.map((p, i) =>
              code === v?.params[i]
                ? intl.formatMessage(defineMessages[code])
                : p,
            ),
          }
        })

        validations.push(...newValidation)
      }
    }

    if (!yup[validationType]) {
      return schema
    }
    let validator = yup[validationType]().typeError(validationTypeError || '')
    validations.forEach((validation) => {
      const { params, type } = validation
      if (!validator[type]) {
        return
      }

      if (type === 'when') {
        const { is, then, otherwise } = params[1]
        const whenParams = {} as {
          is: boolean
          then: (schema: any) => void
          otherwise: (schema: any) => void
        }
        whenParams.is = is
        whenParams.then = (s) => s[then[0].type](...then[0].params)

        if (otherwise) {
          whenParams.otherwise = (s) =>
            s[otherwise[0].type](...otherwise[0].params)
        }

        validator = validator['when'](params[0], whenParams)
      } else {
        validator = validator[type](...params)
      }
    })

    if (!isObject(name)) {
      return schema.concat(yup.object().shape({ [name]: validator }))
    }

    const reversePath = name.split('.').reverse()

    const currNestedObject = reversePath.slice(1).reduce(
      (yupObj, path) => {
        if (!Number.isNaN(Number(path))) {
          return { array: yup.array().of(yup.object().shape(yupObj)) }
        }
        if (yupObj.array) {
          return { [path]: yupObj.array }
        }
        return { [path]: yup.object().shape(yupObj) }
      },
      { [reversePath[0]]: validator },
    )

    const newSchema = yup.object().shape(currNestedObject)
    return schema.concat(newSchema)
  }, yup.object().shape({}))
  return yupSchema
}

export const getGraphQlErrorCodeFromError = (error: ApolloError) => {
  return getGraphQlErrorsCode(error?.graphQLErrors)
}

export const getGraphQlErrorsCode = (graphQlErrors: GraphQLErrors): string => {
  return (
    (graphQlErrors?.[0]?.extensions?.exception as Record<string, any>)
      ?.palqeeErrorCode || null
  )
}

export const doesExceptionCodeExistInGraphqlError = (
  graphqlError: GraphQLError,
  exceptionCode: GraphQLExceptionCode,
): boolean => {
  return (
    (graphqlError?.extensions?.exception as any)?.palqeeErrorCode ===
    exceptionCode
  )
}

export const doesExceptionCodeExistInGraphqlErrors = (
  graphqlErrors: GraphQLError[],
  exceptionCode: GraphQLExceptionCode,
): boolean => {
  return graphqlErrors?.some((error) =>
    doesExceptionCodeExistInGraphqlError(error, exceptionCode),
  )
}

export const graphQLErrorHandler = ({
  error,
  handlers,
  ...props
}: HandleGraphQLErrorProps) => {
  // not sure if this is actually
  // working well
  captureException(error)

  if (error && !isEmpty(handlers)) {
    // we received error code
    // directly in error field [NextAuth]
    if (typeof error === 'string') {
      const handler = handlers[error]
      if (handler) {
        handler({
          code: error,
          ...props,
        })
      } else if (handlers[GraphQLExceptionCode.GenericError]) {
        // generic error message
        handlers[GraphQLExceptionCode.GenericError]({
          code: GraphQLExceptionCode.GenericError,
          ...props,
        })
      }
    } else if ('graphQLErrors' in error) {
      ;(error as any).graphQLErrors.forEach((graphQLErrorsProps) => {
        const { message, locations, path, extensions } = graphQLErrorsProps
        console.log(
          `[GraphQL error]: Message: ${message}, ErrorCode: ${extensions?.code}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}`,
        )

        const { code } = extensions
        const handler = handlers[code]
        if (handler) {
          handler({
            message,
            code,
            ...props,
          })
        }
      })
    } else if ('message' in error) {
      const handler = handlers[(error as any).message]
      if (handler) {
        handler({
          code: error,
          ...props,
        })
      }
    }
  }
}

export const errorHandlerUtil = (props) => (error: ApolloError) => {
  graphQLErrorHandler({
    error,
    ...props,
  })
}

const isomorphicSignOut = async () => {
  if (typeof window !== 'undefined') {
    return signOut({ callbackUrl: LOGIN })
  }

  // ssr
  return signOutRequest()
}

export const onErrorHandler = onError((error) => {
  const { graphQLErrors, networkError, operation } = error
  if (graphQLErrors) {
    // @todo combine this into the handler above
    graphQLErrors.forEach((errors) => {
      const { message, extensions, locations, path } = errors
      console.log(
        `[GraphQL error]: Message: ${message}, ErrorCode: ${extensions?.code}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}, Stacktrace: ${
          (extensions?.exception as any)?.stacktrace
        }, Operation: ${JSON.stringify(
          operation?.operationName,
        )}, Variables: ${JSON.stringify(operation?.variables)} `,
      )

      if (
        extensions?.code === GraphQLExceptionCode.AuthNotAuthenticated ||
        extensions?.code === GraphQLExceptionCode.AuthForbiddenTenant ||
        extensions?.code === GraphQLExceptionCode.JwtTokenNotFromPalqee
      ) {
        return isomorphicSignOut()
      }
    })
  }
  if (networkError)
    console.log(
      `[Network error]: ${networkError} on ${JSON.stringify(
        operation?.operationName,
      )},`,
    )
})

export const getGraphQlErrorMessage = (error: ApolloError) => {
  if ('graphQLErrors' in error) {
    const err = error as any

    if (err.graphQLErrors.length === 1) {
      const message = err.graphQLErrors?.[0].message
      return message
    } else {
      // more than one error how should we handle
    }
  }
}
